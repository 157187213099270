(function(){
    'use strict';

    angular.module('adminApp')
        .controller("manageMessagesCtrl", manageMessagesController);

    manageMessagesController.$inject = ["$scope", "$uibModal", "Notification", "aerosAdminApi"];

    function manageMessagesController($scope, $uibModal, Notification, aerosAdminApi) {

        function convertDate(date) {
            var dateStr = ""+date;
            var timezone = dateStr.match(/\(.+\)/)[0];
            return dateStr.substr(0, 25) + timezone;
        }

        $scope.convertDate = convertDate;

        $scope.$watch("commonReady", function (newVal) {
            if (!newVal) return;

            if (!$scope.allow['editSystemMessages']) return $scope.reject();

            function getAllMessages() {
                aerosAdminApi.getAllSystemMessages()
                    .success(function (data) {
                        var messages = data.messages,
                            now = new Date().getTime();

                        _.each(messages, function (msg) {
                            msg.startDt = new Date(msg.startDt);
                            msg.endDt = new Date(msg.endDt);

                            var start = msg.startDt.getTime(),
                                end = msg.endDt.getTime();

                            msg.inRange = (start < now && now < end);
                        });
                        $scope.messages = messages;
                    })
                    .error(function (err) {
                        Notification.error("Error fetching system messages: " + err.message); // TODO: verify data format of error
                    });
            }

            (function init() {
                getAllMessages();
            }());

            function updateMessage(msg, isNew) {
                var method = (isNew ? "createSystemMessage" : "updateSystemMessage");

                return aerosAdminApi[method](msg)
                    .success(function (data) {
                        getAllMessages();
                        Notification.success("Successfully edited message.");
                    })
                    .error(function (err) {
                        Notification.error("Error editing message: " + err.message);
                    });
            }

            $scope.editMessage = function (msg, isNew) {
                $uibModal.open({
                    size: "md",
                    resolve: {
                        msg: function () {
                            return msg;
                        },
                        targetTypes: function () {
                            return [
                                "Aeros",
                                "Admin",
                                "Gemini",
                                "Mfg"
                            ];
                        }
                    },
                    templateUrl: 'editMessageModal.html',
                    controller: ["$scope", "msg", "targetTypes", function ($scope, msg, targetTypes) {
                        $scope.msg = msg;
                        $scope.targetTypes = targetTypes;
                        $scope.toggleStatus = function () {
                            msg.status = (msg.status === "Active" ? "Inactive" : "Active");
                        };

                        $scope.$watch('msg', function (msg) {
                            if (msg && msg.startDt) {
                                $scope.showStartDt =  convertDate(msg.startDt);
                            }

                            if(msg && msg.endDt) {
                                $scope.showEndDt =  convertDate(msg.endDt);
                            }
                        }, true);
                    }]
                }).result.then(function (editedMsg) {
                    if (!editedMsg) {
                        return getAllMessages();
                    }
                    return updateMessage(editedMsg);
                });
            };

            var blankMessage = {
                message: "",
                startDt: "",
                endDt: "",
                target: ["Aeros"],
                status: "Active"
            };
            $scope.createNewMessage = function () {
                var msg = angular.copy(blankMessage);
                var isNew = true;
                $scope.editMessage(msg, isNew);
            };

            $scope.reactivateMessage = function (msg) {
                msg.status = "Active";
                updateMessage(msg);
            };

            $scope.deactivateMessage = function (msg) {
                msg.status = "Inactive";
                updateMessage(msg);
            };

            $scope.deleteMessage = function (msg) {
                $uibModal.open({
                    size: "md",
                    resolve: {
                        msg: function () {
                            return msg;
                        }
                    },
                    templateUrl: 'confirmDeleteModal.html',
                    controller: ["$scope", "msg", function ($scope, msg) {
                        $scope.msg = msg;
                    }]
                }).result.then(function () {
                    return aerosAdminApi.deleteSystemMessage(msg)
                        .success(function (data) {
                            getAllMessages();
                            Notification.success("Successfully deleted message.");
                        })
                        .error(function (err) {
                            Notification.error("Error deleting message: " + err.message);
                        });
                });
            };
        });
    }

})();

